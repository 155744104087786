<template>
  <div>
    <div class="card full-width">

      <div class="card-header border-0 pt-6 full-width">
        <!--begin::Card title-->

        <div class="card-title full-width">
          <div class="row full-width">
            <div class="col-md-6 fv-row">
              <el-input
                  v-model="filters.search"
                  class="m-2"
                  size="large"
                  placeholder="Please Input"
                  :suffix-icon="Search"
              ></el-input>
            </div>

            <div class="col-md-6 fv-row">
              <el-select clearable v-model="filters.status" class="m-2 select-display-block" placeholder="Select Status" size="large">
                <el-option
                    v-for="item in filterOptions.statuses"
                    :label="item.label"
                    :value="item.value"
                    :key="item.value"
                >
                </el-option>
              </el-select>
            </div>

          </div>
        </div>

      </div>
      <div class="card-body pt-0">
        <Datatable
            class="table-button-actions-width"
            :total="total"
            :rowsPerPage="perPage"
            :loading="loading"
            :table-data="tableData"
            :table-header="tableHeader"
            :enable-items-per-page-dropdown="true"
            @current-change="onCurrentChange"
            @sort="sort"
            @items-per-page-change="changePerPage"
        >
          <template v-slot:cell-id="{ row: data }">{{ data.id }}</template>
          <template v-slot:cell-name="{ row: data }">{{ data.name }}</template>
          <template v-slot:cell-is_active="{ row: data }">
                        <span class="badge fs-7 fw-bolder" :class="`badge-light-${data.status.class}`">{{
                            data.status.label
                          }}</span>
          </template>
          <template v-slot:cell-actions="{ row: data }">
            <el-row>
              <el-button type="default" size="small" @click="viewResource(data.id)" :icon="View" circle></el-button>
              <el-button type="primary" size="small" @click="editResource(data.id)" :icon="Edit" circle></el-button>
              <el-button type="danger"  size="small" @click="deleteResource(data.id)" :icon="Delete" circle></el-button>
            </el-row>
          </template>

        </Datatable>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, ref, onMounted, watch, reactive} from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import ApiAxiosService from "@/core/services/ApiAxiosService";
import { Search , Edit , Delete , View } from '@element-plus/icons-vue'
import {handleError , handleSuccess} from "@/core/helpers/response";
import {APIs} from "@/store/enums/ApiEnums";
import CheckboxTableHeader from "@/components/admin/general/CheckboxTableHeader.vue";
import {confirmDelete} from "@/core/helpers/custom_notification";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {useRouter, useRoute} from 'vue-router'

export default defineComponent({
  name: "roles-list",
  components: {
    Datatable,CheckboxTableHeader
  },

  setup() {
    const router = useRouter();
    const tableHeader = ref([
      {name: "ID", key: "id", sortable: true,},
      {name: "Name", key: "name", sortable: true,},
      {name: "Is Active", key: "is_active", sortable: false,},
      {name: "Actions", key: "actions",},

    ]);

    const tableData = ref([]);
    const loading = ref(true);
    const total = ref(0);
    const currentPage = ref(1);
    const perPage = ref(0);
    const filters = ref({
      search: '',
      status : '',
      page : 1,
      per_page : 10
    });
    const filterOptions = ref({ statuses: []});

    onMounted(() => {
      setCurrentPageBreadcrumbs("Roles", [] , "create-role" );
    });

    const fetchFilerOptions = async () => {
      try {
        let response = await ApiAxiosService.get(APIs.ROLE.filter_options);
        filterOptions.value.statuses = response.data.data.statuses;
      }catch (e) {
        handleError(e)
      }
    }
    const fetchData = async () => {
      loading.value = true;
      try {
        filters.page = currentPage.value;
        let response = await ApiAxiosService.get(APIs.ROLE.list, filters.value);
        let data = response.data.data;
        tableData.value = data.roles;
        total.value = data.pagination_options.last_page;
        currentPage.value = data.pagination_options.currentPage;
        perPage.value = 1;
        loading.value = false;
      } catch (error) {
        handleError(error)
      }

    };

    const onCurrentChange = (value) => {
      tableData.value = [];
      filters.value.page = value;
    };
    const changePerPage = (value) => {
      filters.value.per_page = value;
    }
    const sort = (data) => {
    }
    const viewResource = (id) => {
      router.push({name : 'view-role' , params : {id : id}});
    }
    const editResource = (id) => {
      router.push({name : 'edit-role' , params : {id : id}});
    }
    const deleteResource = async (id) => {
      var checkConfirm = await confirmDelete();
      if(checkConfirm.isConfirmed) {
        Swal.fire({text : 'Pending ...'})
        ApiAxiosService.delete(APIs.ROLE.delete(id)).then(function (response){
          handleSuccess(response , 'swal');
          fetchData();
        }).catch(function (error){
          handleError(error , 'swal');
        });

      }
    }
    watch(filters.value ,(currentValue, oldValue) => {
      fetchData();
    });
    fetchData();
    fetchFilerOptions();

    return {
      tableData,
      tableHeader,
      loading,
      fetchData,
      total,
      perPage,
      onCurrentChange,
      changePerPage,
      filters,
      sort,
      fetchFilerOptions,
      filterOptions,
      Search,View,Edit,Delete,
      viewResource,
      editResource,
      deleteResource

    };
  },

});
</script>
